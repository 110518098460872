import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'

const HomeIndex = () => {
  const siteTitle = 'Balance ton hacker'

  return (
    <Layout>
      <Helmet>
        <title>{siteTitle} - Collectif anti-cybercriminalité</title>
        <meta name="description" content="Découvrez comment vous protéger contre la cybercriminalité. Nos missions : surveiller, alerter, sensibiliser, éduquer."/>
      </Helmet>

      <div className="vh-100" id="main">
        <section className="px-lg-5 mb-4 mb-lg-0" id="homehero">
          <h1 className="text-center">Bienvenue sur Balance ton <mark>Hacker</mark> 👋🏻</h1>
		      <h2 className="text-center">Nous sommes un collectif d'Ethical Hackers. Nous n'agissons dans l'ombre d'aucune organisation ou entreprise. Nos missions : <br/>
          🔎 Surveiller les actes de cybercriminalité<br/>
          📝 Sensibiliser et éduquer de manière ludique<br/>
          🕶 Communiquer les informations aux autorités compétentes<br/>
          </h2>
          <div className="text-center">
            <a href="blog#main" className="button">Découvrir les articles 📖</a>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default HomeIndex
